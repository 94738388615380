<template>
  <section class="helpdesks fade-in" id="helpDesk">
    <div class="helpdesks__container">
      <div class="helpdesks__container__title __header">
        <div class="helpdesks-title" @click="$route.fullPath !== '/helpdesk' ? $router.push('/helpdesk') : ''">
          <h2 class="helpdesks__container-tittle">
            {{ title }}
          </h2>
          <a class="helpdesks-title-button" v-if="$route.fullPath !== '/helpdesk' && $userData.role !== 'model'">
            <iconic class="helpdesks__container-iconic fade-in" name="return"></iconic>
          </a>
        </div>
        <div class="helpdesks__container__title __options">
          <router-link v-if="$userData.role !== 'model' && $route.name === 'resume' && !$route.query.myCases" class="helpdesks__container-buttonMy " :to="{ name: 'resume', query: { myCases: true } }">
            Mis Ticket
          </router-link>
          <router-link
            v-if="$userData.role !== 'model' && $route.name !== 'createcase'"
            :disabled="$route.fullPath.includes('/createcase')"
            class="helpdesks__container-button "
            :to="{ name: 'createcase' }"
          >
            Crear Ticket
          </router-link>
          <button class="helpdesks__container-plus" v-if="$userData.role === 'superadmin' && $route.name === 'createcase'" @click="openModals()">
            <iconic name="plus"></iconic>
          </button>
        </div>
      </div>
    </div>
    <template v-if="validViewForModel">
      <keep-alive include="resumeCases" v-if="$route.name !== 'helpdesk'" >
        <router-view />
      </keep-alive>
      <router-view v-else/>
    </template>
    <UsersView v-if="!validViewForModel"></UsersView>
  </section>
</template>

<script>
  import mixinCrud from "./mixinCrud";
  import UsersView from "@/components/helpdesk/userViewHelpDesk.vue";
  export default {
    name: "viewHelpDesk",
    mixins: [mixinCrud],
    components: { UsersView },
    data() {
      return {
        openModal: false,
      };
    },
    computed: {
      validViewForModel() {
        if (this.$userData.role === "model") return this.$route.name === "detailsCase";
        return true;
      },
    },
    methods: {
      getRole() {
        return this.$userData.role;
      },
      openModals() {
        this.setFilter({ srcElement: { name: "showModal", value: true } });
      },
    },
  };
</script>

<style lang="scss">
  .helpdesks {
    max-width: 100%;
    width: 1440px;
    padding-top: 20px;
    padding-bottom: 80px;
    &__container {
      &-tittle {
        font-size: 32px;
      }
      &-iconic {
        margin: 10px 10px;
        color: #9d9d9d;
        width: 16px;
        height: 10px;
      }
      &-plus {
        display: flex;
        flex-direction: inherit;
        justify-content: center;
        align-items: center;
        background-color: #bd0909;
        color: #fff;
        width: 37.5px;
        height: 37.5px;
        border-radius: 50%;
        cursor: pointer;
        border: none;
        &:hover {
          transition: all 0.3s ease-in-out;
          background: red;
          transform: scale(1.05);
        }
      }
      &-button {
        display: inline-block;
        background-color: $primary-color;
        text-align: center;
        border: none;
        padding: 7px;
        width: 150px;
        color: white;
        border-radius: 5px;
        font-weight: 900;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: white;
          transition: all 0.3s ease-in-out;
          text-decoration: none;
          background: #d41b1b;
        }
      }
      &-buttonMy {
        display: inline-block;
        background-color: white;
        text-align: center;
        border: $primary-color 1px solid;
        padding: 7px;
        width: 100px;
        color: $primary-color;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 15px;
        font-weight: 900;
        transition: all 0.3s ease-in;
        &:hover {
          background-color: $primary-color;
          transition: all 0.3s ease-in-out;
          color: white;
          text-decoration: none;
        }
      }
      &__title {
        @include pocketWidth() {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0px;
        }
      }
      &__title.__header {
        margin: 0 0 $mpadding 0;
      }
      &__title.__options {
        margin: $mpadding 0 $mpadding 0;
      }
    }
    &-title {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: $mpadding;
    }
    &-title-button {
      display: inline-flex;
      width: $mpadding * 2.2;
      height: $mpadding * 2.2;
      background-color: #f0f0f0;
      border-radius: 50%;
      border: none;
      box-shadow: 0px 0.5px 2px 0px #00000069;
      justify-content: center;
      align-items: center;
    }

    @include screen($pocket_width) {
      &__container {
        &__title.__options {
          margin: 0 0 0 0;
        }
      }
    }
    @media (min-width: 768px) {
      padding-bottom: 0px;
    }
  }
</style>
